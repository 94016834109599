import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style

import {NavigationGuardNext} from 'vue-router';
import {getUserStore} from '@/store';
import router from '@/router';
import {APP_NAME} from '@/config/global';

const userStore = getUserStore();

NProgress.configure({showSpinner: false});

router.beforeEach(async (to, from, next: NavigationGuardNext) => {
    // document.title = `${to.meta?.title || ''} - ${APP_NAME}`;
    NProgress.start();
    next();
    NProgress.done();
});

router.afterEach(() => {
    NProgress.done();
});
